const API_BASE_ADDRESS = "https://api.payroll.itsolutionsengly.com/api";

const request = (url, method, data) => {
    let headers = method === 'POST' ? {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'url': window.location.host.split('.')[0]
    } : {};

    let settings = { method, headers };

    if('POST' === method) settings['body'] = JSON.stringify(data);
    return fetch(`${API_BASE_ADDRESS}${url}`, settings);

}
export default request;