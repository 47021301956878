import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignUp,
  userTwitterSignIn
} from 'actions/Auth';

const SignUp = (props) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [full_name, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [replay_password, setReplayPassword] = useState("");
  const [url, setUrl] = useState("");
  const [username, setUsername] = useState("");
  const [errors, setErrors] = useState({});
  const [registerBase, setRegisterBase] = useState({});
  const [valid, setValid] = useState({});

  const dispatch = useDispatch();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);

  const handleChange = (name, validators) => event => {
      let patterns = {
          email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      }

      console.log(errors);

      setRegisterBase({...registerBase, [name]: event.target.value});
      if(validators.required && event.target.value === "") {
        setErrors({ ...errors, [name]: "Este campo es obligatorio*"});
        setValid({...valid, [name]: false});
      }
      else if(validators.pattern) {
          let next;

          switch(validators.pattern) {
              case "email":
                next = patterns['email'].test(event.target.value);  
                  break; 

          }

          if(!next) { 
            setErrors({ ...errors, [name]: "Dato incorrecto!"});
            setValid({...valid, [name]: false});
          }  
          else {
            delete errors[name];
            setErrors(errors);
            setValid({...valid, [name]: true});
          }
      }
      else {
        delete errors[name];
        setErrors(errors);
        setValid({...valid, [name]: false});
      }

  };

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 3000);
    }
    if (authUser !== null) {
      props.history.push('/');
    }

  }, [showMessage, authUser, props.history, dispatch]);

  const isValid = () => {
      return !valid.email && !valid.fullName; 
  } 

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Kiosko">
            <img src={require("assets/images/logo.png")} alt="kiosko" title="kiosko"/>
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header">
            <h1>Crear Cuenta</h1>
          </div>

          <div className="mb-4">
            {/*<h2><IntlMessages id="appModule.createAccount"/></h2>*/}
          </div>

          <div className="app-login-form">
            <form method="post" action="/">
              <TextField
                type="text"
                label="Nombre"
                onChange={handleChange("fullName", { required: true })}
                fullWidth
                defaultValue={registerBase.fullName}
                margin="normal"
                className="mt-0 mb-2"
                helperText={errors.fullName}
                error={errors.fullName && errors.fullName !== ""}
              />

              <TextField
                type="email"
                onChange={handleChange("email", { required: true, pattern: "email" })}
                label={<IntlMessages id="appModule.email"/>}
                fullWidth
                defaultValue={registerBase.email}
                margin="normal"
                className="mt-0 mb-2"
                helperText={errors.email}
                error={errors.email && errors.email !== ""}
              />

              <TextField
                  type="text"
                  label="Nombre del Cliente"
                  onChange={(event) => setName(event.target.value)}
                  fullWidth
                  defaultValue={name}
                  margin="normal"
                  className="mt-0 mb-2"
              />

              <TextField
                type="password"
                onChange={(event) => setPassword(event.target.value)}
                label={<IntlMessages id="appModule.password"/>}
                fullWidth
                defaultValue={password}
                margin="normal"
                className="mt-0 mb-4"
              />

              <TextField
                  type="password"
                  onChange={(event) => setReplayPassword(event.target.value)}
                  label="Repetir-password"
                  fullWidth
                  defaultValue={replay_password}
                  margin="normal"
                  className="mt-0 mb-4"
              />

              <TextField
                  type="text"
                  label="Url"
                  onChange={(event) => setUrl(event.target.value)}
                  fullWidth
                  defaultValue={url}
                  margin="normal"
                  className="mt-0 mb-2"
              />

              <TextField
                  type="text"
                  label="Nombre de Usuario"
                  onChange={(event) => setUsername(event.target.value)}
                  fullWidth
                  defaultValue={username}
                  margin="normal"
                  className="mt-0 mb-2"
              />

              <div className="mb-3 d-flex align-items-center justify-content-between">
                <Button variant="contained" onClick={() => {
                  dispatch(showAuthLoader());
                  dispatch(userSignUp({name, email, full_name, password, replay_password, url, username, registerBase}));
                }} color="primary"
                  disabled={isValid()}
                >
                  <IntlMessages id="appModule.regsiter"/>
                </Button>
                <Link to="/signin">
                  <IntlMessages id="signUp.alreadyMember"/>
                </Link>
              </div>
              <div className="app-social-block my-1 my-sm-3">
                <IntlMessages
                  id="signIn.connectWith"/>
                <ul className="social-link">
                  <li>
                    <IconButton className="icon"
                                onClick={() => {
                                  dispatch(showAuthLoader());
                                  dispatch(userFacebookSignIn());
                                }}>
                      <i className="zmdi zmdi-facebook"/>
                    </IconButton>
                  </li>

                  <li>
                    <IconButton className="icon"
                                onClick={() => {
                                  dispatch(showAuthLoader());
                                  dispatch(userTwitterSignIn());
                                }}>
                      <i className="zmdi zmdi-twitter"/>
                    </IconButton>
                  </li>

                  <li>
                    <IconButton className="icon"
                                onClick={() => {
                                  dispatch(showAuthLoader());
                                  dispatch(userGoogleSignIn());

                                }}>
                      <i className="zmdi zmdi-google-plus"/>
                    </IconButton>
                  </li>

                  <li>
                    <IconButton className="icon"
                                onClick={() => {
                                  dispatch(showAuthLoader());
                                  dispatch(userGithubSignIn());
                                }}>
                      <i className="zmdi zmdi-github"/>
                    </IconButton>
                  </li>
                </ul>
              </div>

            </form>
          </div>
        </div>

      </div>

      {
        loader &&
        <div className="loader-view">
          <CircularProgress/>
        </div>
      }
      {showMessage && NotificationManager.success(alertMessage)}
      <NotificationContainer/>
    </div>
  )
};


export default SignUp;
